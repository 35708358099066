.card__content--info {
  :first-child {
    font-weight: 400;
  }
  .form-control__label {
    @include respond-to(768) {
      align-items: initial;
    }
  }
}
