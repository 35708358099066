.card--return-info {
  .card__content {
    &:first-child {
      > h3 {
        margin: 0.3em 0 0.9em !important;
        font-size: 16px !important;
      }
      > p {
        margin: 0 0 0.8em;
      }
    }
    :last-child {
      font-size: 16px;
      font-weight: 500;
      line-height: 2em;
    }
  }
}
