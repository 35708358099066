.loading-circle {

  &:before {
    display: inline-block;
    content: '';
    height: 1em;
    width: 1em;
    margin-top: calc-target(5, $body2-size);
    margin-right: calc-target(5, $body2-size);
    background-size: cover;
    vertical-align: top;
    animation: spin 1s linear infinite;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='rotate(180 6 6)'%3E%3Ccircle cx='6' cy='6' r='2.72727'/%3E%3Cpath d='M6 12c-3.3137 0-6-2.6863-6-6s2.6863-6 6-6c.55228 0 1 .44772 1 1s-.44772 1-1 1C3.79086 2 2 3.79086 2 6s1.79086 4 4 4 4-1.79086 4-4c0-.55228.44772-1 1-1s1 .44772 1 1c0 3.3137-2.6863 6-6 6z' fill='%23DDE2E6' fill-rule='nonzero'/%3E%3C/g%3E%3Cg%3E%3Ccircle cx='6' cy='6' r='2.72727'/%3E%3Cpath d='M6 12c-3.3137 0-6-2.6863-6-6s2.6863-6 6-6c.55228 0 1 .44772 1 1s-.44772 1-1 1C3.79086 2 2 3.79086 2 6s1.79086 4 4 4 4-1.79086 4-4c0-.55228.44772-1 1-1s1 .44772 1 1c0 3.3137-2.6863 6-6 6z' fill='%230272B6' fill-rule='nonzero'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");  }
}
