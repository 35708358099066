.form-control-group--flex-at-768 {
  @include respond-to(768) {
    width: calc(100% + 12px);
    margin-left: -6px;
    display: flex;
    margin-bottom: 24px;
  }

  > .form-control-group__item {
    @include respond-to(768) {
      padding: 0 8px;
      width: 50%;
    }

    .form-control,
    .form-control-group {
      @include respond-to(768) {
        margin-bottom: 0;
      }
    }
  }
}

.form-control-group-loading--flex-at-768 {
  width: calc(100% + 12px);
  margin-left: -6px;
  display: flex;
  margin-bottom: 24px;

  > .form-control-group__item {
    padding: 0 8px;
    width: 95%;

    .form-control,
    .form-control-group {
      @include respond-to(768) {
        margin-bottom: 0;
      }
    }
  }
  > .form-control-group__item-loading {
    @include respond-to(768) {
      padding: 0 8px;
    }
    margin: 42px auto auto auto;
    width: 5%;
  }
}

.form-control-group {
  > .form-control-group__item--1of3-at-1024 {
    @include respond-to(1024) {
      width: 33.3%;
    }
  }

  > .form-control-group__item--2of3-at-1024 {
    @include respond-to(1024) {
      width: 66.6%;
    }
  }
}

.form-control__input {
  > input {
    height: 55px;
  }
  > select {
    height: 55px;
  }
}

.form-control {
  max-width: 100%;

  .form-control__label {
    .form-control__label--helper {
      font-size: 12px;
      padding-left: 6px;
    }
  }
}
