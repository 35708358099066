.typeahead__address-row {
  padding: 8px;
  &:hover {
    background-color: $blue-deep;
    color: $white;
    cursor: pointer;
  }
}

.typeahead__selection {
  background-color: $blue-deep;
  color: $white;
}
