.card-group--final-review {
  .card-group__item {
    .key-value--inline-at-768 {
      text-align: left;
    }
    .card__action {
      margin-bottom: auto;
    }
    .link-group {
      flex-basis: 340px;
    }
    .document-review {
      display: flex;
      flex-basis: 340px;
      flex-wrap: wrap;
    }
    .document-review__value {
      flex-basis: 65%;
      height: 20px;
      margin-bottom: 18px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 0px;
    }
    svg {
      margin-right: 6px;
    }
  }
}
