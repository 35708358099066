.page-content {
  min-height: 85vh;
}

// @TODO this is to add a colon after the label on the confirmation page
// after creating an issue. On the UX it is supposed to have a colon
// when it is inline and remove the colon on mobile view
.add-colon-after-label::after {
  @include respond-to(768) {
    content: ':';
  }
}

// specifically adding this to not overwrite other components that use KeyValue
// Putting this here, because ADC styles will overwrite it in the _card-group file
.key-value.key-value--inline-at-768.confirmation {
  .key-value__key {
    margin-right: 5px;
    width: initial;
  }
  .body2 {
    margin: initial;
  }
}
.form-control__error.error-top {
  padding-bottom: 12px;
}

// keep these at the bottom
.mb0 {
  margin-bottom: 0;
}
.mb6 {
  margin-bottom: 6px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb24 {
  margin-bottom: 24px;
}
.mb30 {
  margin-bottom: 30px;
}

.mr6 {
  margin-right: 6px;
}

.ml6 {
  margin-left: 6px;
}

.ml12 {
  margin-left: 12px;
}

.mr12 {
  margin-right: 12px;
}

.pt0 {
  padding-top: 0;
}
.pt6 {
  padding-top: 6px;
}
.pb6 {
  padding-bottom: 6px;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.ws-pre {
  white-space: pre;
}

.text-align-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.outline-none {
  outline: none;
}

.fs12 {
  font-size: 12px;
}

.fw100 {
  // Thin
  font-weight: 100;
}

.fw200 {
  // Extra Light
  font-weight: 200;
}

.fw300 {
  // Light
  font-weight: 300;
}

.fw400 {
  // Regular
  font-weight: 400;
}

.fw500 {
  // Medium
  font-weight: 500;
}

.fw600 {
  // Bold
  font-weight: 600;
}

:focus {
  outline: #3279bf solid 1px;
  box-shadow: inset 0px 0px 2px 2px #3279bf;
}

input:focus {
  box-shadow: 0px 0px 2px 2px #3279bf;
}

input:hover {
  box-shadow: 0px 0px 2px 2px #3279bf;
}

h1:focus {
  outline: none;
  box-shadow: none;
}

.p24 + [class*="button"] {
  margin-left: 24px;
}
