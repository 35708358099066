.key-value {
  &--inline {
    display: flex;
    justify-content: space-between;
  }
  &--column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .key-value__key {
    &.mb0 {
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  &.outage-confirmation div {
    @include respond-to(768) {
      flex: 0 1 50%;
    }
  }
}

.key-value__value--right-align {
  @include respond-to(768) {
    text-align: right;
  }
}
