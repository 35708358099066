.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba($smolder, 0.3);
}

.modal--is-open {
  display: block;
}

.modal__content {
  max-width: 100%;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 24px;
  right: 24px;
  z-index: 2;


  @include respond-to(680) {
    width: 680px;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    left: 50%;
    right: auto
  }

  > .card {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    margin-bottom: 0;
  }
}

.modal__close {
  top: 20px;
  right: 20px;
  position: absolute !important; /* all-digital styles override */
  color: $cool-grey10;
  z-index: 1;

  @include respond-to(680) {
    top: 30px;
  }

  &::before {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    background-size: cover;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20width%3D%2222%22%20height%3D%2222%22%20viewBox%3D%220%200%2022%2022%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%3Cg%20stroke-width%3D%222%22%20stroke%3D%22%23868F96%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%3E%0A%20%20%20%20%3Cpath%20d%3D%22M21%2021L1%201M1%2021L21%201%22%2F%3E%0A%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E');
  }
}

.modal--full-page {
  .modal__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    transform: none;
  }

  .page-section {
    min-height: 100vh;
  }
}
