.svg-icon {
  display: inline-block;
  width: 60px;
  height: 60px;

  svg {
    max-width: 100%;
    max-height: 100%;
  }
}

.svg-icon--12 {
  width: 12px;
  height: 12px;
}

.svg-icon--22 {
  width: 22px;
  height: 22px;
}

.svg-icon--26 {
  width: 26px;
  height: 26px;
}

.svg-icon--28 {
  width: 28px;
  height: 28px;
}

.svg-icon--32 {
  width: 32px;
  height: 32px;
}

.svg-icon--34 {
  width: 34px;
  height: 34px;
}

.svg-icon--36 {
  width: 36px;
  height: 36px;
}

.svg-icon--40 {
  width: 40px;
  height: 40px;
}

.svg-icon--42 {
  width: 42px;
  height: 42px;
}

.inline-icon {
  display: flex;

  &__icon {
    display: inline-block;
    margin-right: 12px;
    flex: 0 0 auto;
  }

  &__content {
    display: inline;
    vertical-align: middle;
    flex: 1 1 auto;
    > p {
      margin-bottom: 0;
    }
  }
}

.svg-icon--non-reactive {
  pointer-events: none;
}
