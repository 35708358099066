.ma-grid {
  clear: both;

  @include respond-to(768) {
    margin-left: -12px;
    width: calc(100% + 24px);
    display: flex;
  }

  > [class*="ma-grid__col"] {
    @include respond-to(768) {
      padding: 0 12px;
    }
  }
}

.ma-grid-850 {
  clear: both;

  @include respond-to(850) {
    margin-left: -12px;
    width: calc(100% + 24px);
    display: flex;
  }

  > .ma-grid__col {
    @include respond-to(850) {
      padding: 0 12px;
      width: 50%;
    }
  }
}

.ma-grid__col--1of2 {
  @include respond-to(768) {
    width: 50%;
  }
}

.ma-grid__col--1of3 {
  @include respond-to(768) {
    width: 33.3%;
  }
}

.ma-grid__col--1of4 {
  @include respond-to(768) {
    width: 50%;
  }

  @include respond-to(1024) {
    width: 25%;
  }
}

.ma-grid__col--2of3 {
  @include respond-to(768) {
    width: 66.6%;
  }
}

.ma-grid__col--2of4 {
  @include respond-to(768) {
    width: 100%;
  }

  @include respond-to(1024) {
    width: 50%;
  }
}

.ma-grid__col--3of4 {
  @include respond-to(768) {
    width: 75%;
  }
}

.ma-grid__col--inline {
  display: flex;
  align-items: center;
  .ma-grid__col--inline-list {
    margin: 0;
  }
}
