.card--error {
  border: 1px solid $haute;
  border-radius: 3px;
}

.card--view-more {
  .card__content {
    display: flex;
    justify-content: center;
  }
}

.card--mini-message {
  display: flex;
  text-align: left;

  .card__icon {
    margin-right: 6px;

    @include respond-to(768) {
      position: relative;
    }

    .svg-icon {
      @include respond-to(768) {
        // use position: absolute so the card height
        // is based on the text not the icon
        @include vertical-align;
      }
    }
  }

  .card__content-inner {
    flex: 1;
  }

  .card__content {
    @include respond-to(768) {
      display: flex;
      padding-left: 40px; // 34 icon + 6 margin
    }
  }
}

.card--bill {
  border-left: 5px solid $minty;

  @include ui-grey {
    border-left: 5px solid $minty;
  }
}
