.document-uploader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .card__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .card__drop-zone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 200px;
      margin-top: 12px;
      margin-bottom: 12px;
      height: 10px;
      width: 100%;
      border-radius: 5px;
      border: 1px dashed $cool-grey8;

      &.dragging {
        border: 1px dashed $blue-deep;
      }

      &.error {
        border: 1px dashed $haute;
        margin-bottom: 4px;
      }
    }
  }

  .form-control__error {
    width: 100%;
    text-align: left;
    margin-bottom: 12px;
  }

  .upload__details {
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 10px;

    h3 {
      width: 100%;
      text-align: left;
    }

    .card-group {
      border: 1px solid $cool-grey6;
      border-radius: 5px;
      box-shadow: none;
      width: 100%;

      .card-group__item {
        max-width: 100%;
        padding: 0px;

        &.uploading {
          border-bottom: 3px solid $blue-sky;
        }

        &.done {
          border-bottom: 3px solid $minty;
        }

        &.failed {
          border-bottom: 3px solid $haute;
        }

        .card__content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: initial;

          .upload__details--fileName {
            flex-basis: 50%;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            svg {
              margin-right: 10px;
            }
          }

          .body4 {
            margin-bottom: 0px;
          }

          .upload__details--status {
            display: flex;
          }

          p {
            margin-bottom: 0;
            margin-right: 10px;
          }

          svg {
            margin-bottom: 4px;
          }
        }

        .card {
          padding: 10px;
        }
      }
    }
  }
}
