.number-circle {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 3px;

  border: 1px solid $card-border-color;
  color: $card-border-color;
  text-align: center;


}
