.page-section--banner {
  text-align: center;

  .page-section__wrapper {
    max-width: 728px; // 8 col + gutter
  }

  &.bereavement-banner {
    .page-section__wrapper {
      max-width: 792px;
    }
  }
}
