.card-group--instructions {
  .card-group__item {
    .card__media {
      margin-left: 18px;
    }
  }
}

.card-group--review {
  .card-group__item {
    .key-value--inline-at-768 {
      text-align: left;
    }
    .card__action {
      margin-bottom: auto;
    }
    .link-group {
      flex-basis: 340px;
    }
    .docs-group {
      display: flex;
      flex-basis: 340px;
      flex-wrap: wrap;
    }
    .docs-group__value {
      flex-basis: 340px;
      height: 18px;
      margin-bottom: 18px;
    }
  }
}

.card-group--media {
  .card-group__item {
    .form-control--boolean .form-control__label {
      align-items: center;
    }
    .form-control {
      margin-bottom: 0px;
    }
    .card__media {
      left: 45px;
    }
    .card--has-media .card__content {
      padding-left: 80px;
    }
  }
}

.card-group__item--asset {
  &-review,
  &-confirmation {
    .card__media {
      height: 120px;
      width: 85px;
      left: 13px;
      @include respond-to(768) {
        left: 25px;
      }
    }
  }
  &-review {
    .card__content {
      padding-left: 106px;
    }
  }
  &-confirmation {
    .card__content {
      padding-left: 105px;
      height: 108px;
    }
  }
}
