.utility-button {
  padding-top: 12px;
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 12px;
  border: 1px solid $cool-grey6;

  &:hover {
    background-color: $button-background-color;
    color: $button-text-color;
    border-color: $blue-sky;
  }

  .utility-button__content {
    margin-left: -6px; // corrects for svg margin
  }
}
