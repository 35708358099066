@include respond-to(768) {
  .card--action-right-at-768 .card__content {
    text-align: left;
  }
}

.form-boolean__instructions {
  padding-left: 42px;

}
